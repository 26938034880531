import * as React from "react";
const PayoutDetails = () => (
  <svg
    className="w-8" 
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
  >
    <mask
      id="mask0_1286_1665"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={36}
      height={36}
    >
      <rect width={36} height={36} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1286_1665)">
      <path
        d="M8.925 22.425H20.8001L23.2999 19.9249H8.925V22.425ZM8.925 15.975H17.7V13.4749H8.925V15.975ZM5.50012 10.0001V25.9751H17.25L14.7251 28.5H3V7.5H33V12.7249H30.4999V10.0001H5.50012ZM34.3249 18.15C34.4499 18.275 34.5124 18.425 34.5124 18.6C34.5124 18.775 34.4499 18.925 34.3249 19.05L32.9501 20.4251L30.825 18.3L32.25 16.875C32.375 16.75 32.5084 16.6875 32.6501 16.6875C32.7916 16.6875 32.9249 16.75 33.0499 16.875L34.3249 18.15ZM19.7501 31.5V29.3749L29.8751 19.2499L31.9999 21.375L21.8749 31.5H19.7501Z"
        fill="#E5637F"
      />
    </g>
  </svg>
);
export default PayoutDetails;
