import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AnyayaMultiColor from "../../assets/svg/AnyayaMultiColor";
import Client from "../../assets/svg/Client";
import Merchant from "../../assets/svg/Merchant";
import ClientMobile from "../../assets/svg/ClientMobile";
import MerchantMobile from "../../assets/svg/MerchantMobile";
import { updateLoginModal } from "../../store/slicers/authSlice";
import { useNavigate } from "react-router-dom";
import ClientMobileActive from "../../assets/svg/ClientMobileActive";
import ClientActive from "../../assets/svg/ClientActive";
import MerchantMobileActive from "../../assets/svg/MerchantMobileActive";
import MerchantActive from "../../assets/svg/MerchantActive";
import Confetti from "react-confetti";
const LandingScreen = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [loginType, setLoginType] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProceed = () => {
    if (loginType === "client") {
      navigate("/client");
    } else {
      navigate("/merchant");
    }
  };

  return (
    <div className="flex  flex-col items-center justify-center h-screen bg-white relative">
      {/* <div className="confetti-top h-full w-full absolute top-0"></div> */}
      <div className="w-full max-w-md px-4 py-14 ">
        <div className="flex justify-center">
          <h1 className="text-4xl font-bold text-gray-800">Welcome to</h1>
        </div>

        <div className="flex justify-center mt-4">
          <div className="w-full h-auto max-w-xs text-center-webkit">
            <AnyayaMultiColor className="h-full w-46" />
          </div>
        </div>

        <div className="mt-6 text-center">
          <p className="text-center text-gray-600">
            Please select your role to proceed
          </p>

          <div className="flex justify-center mt-6 space-x-4">
            <div
              className="cursor-pointer"
              onClick={() => setLoginType("client")}
            >
              {loginType === "client" ? (
                <div>
                  <div className="block lg:hidden w-full">
                    <ClientMobileActive />
                  </div>
                  <div className="hidden lg:block w-full">
                    <ClientActive />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="block lg:hidden w-full">
                    <ClientMobile />
                  </div>
                  <div className="hidden lg:block w-full">
                    <Client />
                  </div>
                </div>
              )}
            </div>

            <div
              className="cursor-pointer"
              onClick={() => setLoginType("merchant")}
            >
                  {loginType === "merchant" ? (
                <div>
                  <div className="block lg:hidden w-full">
                    <MerchantMobileActive />
                  </div>
                  <div className="hidden lg:block w-full">
                    <MerchantActive />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="block lg:hidden w-full">
                    <MerchantMobile />
                  </div>
                  <div className="hidden lg:block w-full">
                    <Merchant />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Proceed Button */}
          <button
            disabled={!loginType}
            className={`mt-8 w-80 lg:w-full py-3 text-white text-lg font-semibold rounded-md ${
              !loginType
                ? "bg-sunrise-600 cursor-not-allowed"
                : "bg-sunrise-600 hover:opacity-90"
            }`}
            onClick={handleProceed}
          >
            Proceed
          </button>

          {/* Login Option */}
          <div className="mt-6 text-center">
            <p className="text-gray-600">
              Already have an account?{" "}
              <span
                className="text-sunrise-600 font-medium cursor-pointer"
                onClick={() => {
                  dispatch(updateLoginModal({ status: true }));
                }}
              >
                Login here
              </span>
            </p>
          </div>
        </div>
      </div>
      <Confetti
          width={window.innerWidth - 20}
          height={window.innerHeight - 20}
          tweenDuration={1}
        />
    </div>
  );
};

export default LandingScreen;
