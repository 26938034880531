import React, { useEffect, useRef, useState } from "react";
import Close from "../../assets/svg/Close";
import { config } from "../../utils/config";
import Error from "../../assets/svg/Error";
import Loader from "../common/Loader";
import { merchantApplication } from "../../api/merchant";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountInfo, updateWelcome } from "../../store/slicers/authSlice";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Business Information
  const [businessName, setBusinessName] = useState("");
  const [natureOfBusiness, setNatureOfBusiness] = useState("");

  // Legal Information
  const accountInfo = useSelector((state) => state.auth.account_info || "");
  console.log(accountInfo)
  const [legalFirstName, setLegalFirstName] = useState(accountInfo?.firstname);
  const [legalSurname, setLegalSurname] = useState(accountInfo?.lastname);
  const [legalMiddleName, setLegalMiddleName] = useState(
    accountInfo?.middlename
  );
  const [suffix, setSuffix] = useState(accountInfo?.suffix);

  // Address Information
  const [businessAddress, setBusinessAddress] = useState("");

  // Contact Information
  const [contactNumber, setContactNumber] = useState(accountInfo?.mobile_no);
  const [emailAddress, setEmailAddress] = useState(accountInfo?.email_address);

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(accountInfo?.profile_img);
  const [selectedImage, setSelectedImage] = useState(null);

  const [idFiles, setIdFiles] = useState([]);
  const [idPreviews, setIdPreviews] = useState([]);

  const [permitFiles, setPermitFiles] = useState([]);
  const [permitPreviews, setPermitPreviews] = useState([]);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const openModal = (index) => {
    setSelectedImage(idPreviews[index]);
  };

  const handleFileChange = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (allowedFileTypes.includes(file.type)) {
        setSelectedFile(file);

        // Display the selected image preview
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setImagePreview(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleRegister = async () => {
    if (idFiles.length !== 0 && permitFiles.length !== 0) {
      setIsLoading(true);
      try {
        const _requestData = {
          flag: "save",
          firstname: legalFirstName,
          middlename: legalMiddleName,
          lastname: legalSurname,
          mobile_no: contactNumber,
          merchant_name: businessName,
          description: natureOfBusiness,
          address: businessAddress,
          email_address: emailAddress,
          profile_img: selectedFile,
          doc_file: permitFiles,
          id_photo: idFiles,
        };

        const response = await merchantApplication(_requestData);
        if (response.error === 0) {
          const accountInfo = response.data.account_info;
          dispatch(updateAccountInfo(accountInfo));
          dispatch(updateWelcome(true));
          navigate("/merchant/home")
          setIsLoading(false);
        } else {
          setError(response.error_msg);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        setError("Failed. Please try again.");
      }
    } else {
      setError("Please upload identity documents and business permits.");
    }
  };

  const renderInputField = (
    id,
    state,
    setState,
    placeholder,
    disabled = false,
    autoFocus = false
  ) => (
    <div
      id={id}
      className={`w-full lg:w-1/2 items-center p-2 border rounded-xl text-sm`}
    >
      <label htmlFor={id} className="px-2 text-xs text-gray-400">
        {placeholder}
      </label>
      <div className="flex">
        <input
          autoFocus={autoFocus}
          disabled={disabled}
          className="resize-none border-none px-2 outline-none w-full bg-transparent"
          id={id}
          type="text"
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
      </div>
    </div>
  );

  const handleIdFileChange = (e) => {
    const files = e.target.files;
  
    if (files) {
      const newFiles = Array.from(files);
      setIdFiles([...idFiles, ...newFiles]);
  
      const newPreviews = Array.from(files).map((file) =>
        file.type.includes("pdf") ? "PDF" : URL.createObjectURL(file)
      );
      setIdPreviews([...idPreviews, ...newPreviews]);
    }
  };
  

  const removeIdPreview = (index) => {
    const updatedPreviews = idPreviews.filter((_, i) => i !== index);

    const updatedFiles = idFiles.filter((_, i) => i !== index);
    setIdFiles(updatedFiles);

    setIdPreviews(updatedPreviews);
  };

  const renderIdPreviews = () => {
    return idPreviews.map((preview, index) => (
      <div key={index} className="relative">
        {preview === "PDF" ? (
          <div className="mt-3 h-40 w-40 border rounded-lg bg-gray-200 flex items-center justify-center">
            PDF
          </div>
        ) : (
          <img
            src={preview}
            alt={`ID ${index + 1}`}
            onClick={() => openModal(index)}
            className="mt-3 h-40 w-40 border rounded-lg bg-no-repeat bg-center bg-contain cursor-pointer"
          />
        )}
        <button
          onClick={() => removeIdPreview(index)}
          className="absolute top-0 right-0 mt-4 mr-1 bg-red-500 text-white rounded-full text-xs"
        >
          <Close className="w-4 h-4" />
        </button>
      </div>
    ));
  };

  const handlePermitFileChange = (e) => {
    const files = e.target.files;
  
    if (files) {
      const newFiles = Array.from(files);
      setPermitFiles([...permitFiles, ...newFiles]);
  
      const newPreviews = Array.from(files).map((file) =>
        file.type.includes("pdf") ? "PDF" : URL.createObjectURL(file)
      );
      setPermitPreviews([...permitPreviews, ...newPreviews]);
    }
  };
  
  const removePermitPreview = (index) => {
    const updatedPreviews = permitPreviews.filter((_, i) => i !== index);

    const updatedFiles = permitFiles.filter((_, i) => i !== index);
    setPermitFiles(updatedFiles);

    setPermitPreviews(updatedPreviews);
  };

  const renderPermitPreviews = () => {
    return permitPreviews.map((preview, index) => (
      <div key={index} className="relative">
        {preview === "PDF" ? (
          <div className="mt-3 h-40 w-40 border rounded-lg bg-gray-200 flex items-center justify-center">
            PDF
          </div>
        ) : (
          <img
            src={preview}
            alt={`Permit ${index + 1}`}
            onClick={() => setSelectedImage(permitPreviews[index])}
            className="mt-3 h-40 w-40 border rounded-lg bg-no-repeat bg-center bg-contain cursor-pointer"
          />
        )}
        <button
          onClick={() => removePermitPreview(index)}
          className="absolute top-0 right-0 mt-4 mr-1 bg-red-500 text-white rounded-full text-xs"
        >
          <Close className="w-4 h-4" />
        </button>
      </div>
    ));
  };

  return (
    <div className="px-10 lg:px-40 xl:px-56 pb-10 pt-6">
      <p className="text-xl text-center mb-5">Merchant Registration</p>
      <div className="flex  flex-col lg:flex-row gap-4">
        <div id="form" className="items-center flex flex-1 flex-col gap-y-4">
          {page === 1 && (
            <>
              <div className="w-full lg:w-1/2">
                <div className="relative justify-center flex  w-full cursor-pointer">
                  {imagePreview ? (
                    <img
                      draggable="false"
                      src={imagePreview}
                      alt="Selected"
                      className="mt-6 h-40 w-40 border rounded-full bg-no-repeat bg-center bg-contain"
                    />
                  ) : (
                    <div className="rounded-full defaulticon w-40 h-40 bg-no-repeat bg-cover"></div>
                  )}
                </div>

                <label
                  className={
                    "underline bg-white text-primary-500  mt-5 px-10 flex justify-center w-full text-sm  rounded-xl cursor-pointer"
                  }
                >
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  {selectedFile ? "Change Photo" : "Upload Photo"}
                </label>
              </div>
              <div className="w-full lg:w-1/2 text-sm">
                Personal Information
              </div>
              {/* Legal Information */}
              {renderInputField(
                "legalFirstName",
                legalFirstName,
                setLegalFirstName,
                "Legal First Name",
                true,
                true
              )}
              {renderInputField(
                "legalSurname",
                legalSurname,
                setLegalSurname,
                "Legal Surname",
                true
              )}
              {renderInputField(
                "legalMiddleName",
                legalMiddleName,
                setLegalMiddleName,
                "Legal Middle Name",
                true
              )}
              {renderInputField("suffix", suffix, setSuffix, "Suffix", true)}

              {error && (
                <span className="w-full lg:w-1/2 text-xs text-red-500 flex flex-row item-center">
                  <Error className="w-3 mr-1 self-center" />
                  {error}
                </span>
              )}
              <div
                onClick={() => {
                  if (legalFirstName && legalSurname) {
                    setPage(2);
                    setError("");
                  } else {
                    setError("Please enter required fields.");
                  }
                }}
                className="w-full lg:w-1/2 text-right text-sm text-primary-500 cursor-pointer"
              >
                Next {`>`}{" "}
              </div>
            </>
          )}
          {page === 2 && (
            <>
              <div className="w-full lg:w-1/2 text-sm">
                Business Information
              </div>
              {/* Business Information */}
              {renderInputField(
                "businessName",
                businessName,
                setBusinessName,
                "Business Name",
                false,
                true
              )}
              {renderInputField(
                "natureOfBusiness",
                natureOfBusiness,
                setNatureOfBusiness,
                "Nature of Business"
              )}
              {renderInputField(
                "businessAddress",
                businessAddress,
                setBusinessAddress,
                "Business Address"
              )}
              {/* Contact Information */}
              {renderInputField(
                "contactNumber",
                contactNumber,
                setContactNumber,
                "Contact Number",
                true
              )}

              {renderInputField(
                "emailAddress",
                emailAddress,
                setEmailAddress,
                "Email Address",
                true
              )}
              <div
                className={`w-full lg:w-1/2 items-center p-2 border rounded-xl text-sm`}
              >
                <div className="flex flex-row">
                  <label className="px-2  w-full lg:w-40  self-center text-xs text-gray-400">
                    Identity Documents
                  </label>
                  <label
                    htmlFor="files"
                    className="cursor-pointer border border-primary-500 rounded-lg p-2"
                  >
                    <p className="px-2 cursor-pointer text-xs text-primary-500">
                      Upload
                    </p>
                  </label>
                </div>
                <div className="flex">
                  <input
                    id="files"
                    type="file"
                    accept="image/*,.pdf" // Accepts image files and PDF files
                    className="mt-2 hidden"
                    onChange={handleIdFileChange}
                    multiple
                  />
                </div>

                <div className="flex justify-start flex-wrap gap-4">
                  {renderIdPreviews()}
                </div>
              </div>
              <div
                className={`w-full lg:w-1/2 items-center p-2 border rounded-xl text-sm`}
              >
                <div className="flex flex-row">
                  <label className="w-full lg:w-40  px-2 self-center text-xs text-gray-400">
                    Business Permit and/or Any Valid Identification
                  </label>
                  <label
                    htmlFor="permit"
                    className="cursor-pointer border border-primary-500 rounded-lg p-2"
                  >
                    <p className="px-2 cursor-pointer text-xs text-primary-500">
                      Upload
                    </p>
                  </label>
                  <label className="hidden lg:flex px-2 self-center text-xs text-gray-400">
                    eg. DTI, Barangay, Mayor's, BIR
                  </label>
                </div>
                <div className="flex">
                  <input
                    id="permit"
                    type="file"
                    accept="image/*,.pdf" // Accepts image files and PDF files
                    className="mt-2 hidden"
                    onChange={handlePermitFileChange}
                    multiple
                  />
                </div>

                <div className="flex justify-start flex-wrap gap-4">
                  {renderPermitPreviews()}
                </div>
              </div>

              {error && (
                <span className="w-full lg:w-1/2 text-xs text-red-500 flex flex-row item-center">
                  <Error className="w-3 mr-1 self-center" />
                  {error}
                </span>
              )}

              <div className="w-full lg:w-1/2 mt-4 text-xs text-gray-400">
                By selecting Agree & Register, I agree to{" "}
                <span
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL + "/terms-and-conditions",
                      "_blank"
                    );
                  }}
                  className="underline cursor-pointer"
                >
                  Anyaya’s Terms & Conditions
                </span>{" "}
                and acknowledge the{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    window.open(
                      config.ANYAYA_URL + "/privacy-policy",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy.
                </span>
              </div>

              <div className="w-full lg:w-1/2 flex flex-row justify-between text-sm text-primary-500 cursor-pointer">
                <div onClick={() => setPage(1)}>{"<"} Back</div>
                <div
                  onClick={() => {
                    if (!isLoading) {
                      handleRegister();
                    }
                  }}
                >
                  {isLoading ? (
                    <Loader borderColor="border-primary-500" />
                  ) : (
                    "Agree & Register"
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
          <div
            onClick={() => setSelectedImage(null)}
            className="fixed inset-0 bg-black opacity-50"
          ></div>
          <div className="bg-white rounded-3xl relative py-6 mx-4 w-500">
            <div className="modal-content">
              <img src={selectedImage} alt="Selected ID" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;
