import * as React from "react";
const Update = () => (
  <svg
    className="w-8" 
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
  >
    <mask
      id="mask0_1286_1654"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={36}
      height={36}
    >
      <rect width={36} height={36} fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1286_1654)">
      <path
        d="M4.5 23.7251V21.225H16.1501V23.7251H4.5ZM4.5 17.5999V15.1001H22.3249V17.5999H4.5ZM4.5 11.475V8.97485H22.3249V11.475H4.5ZM24.4751 28.4749L19.3001 23.2999L21.0499 21.525L24.4751 24.9L31.2251 18.15L33 19.95L24.4751 28.4749Z"
        fill="#E5637F"
      />
    </g>
  </svg>
);
export default Update;
