import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MetaTags from "../common/MetaTags";
import Helmet from "react-helmet";
import { BetterBrowsing } from "../common/BetterBrowsing";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Guest from "../../assets/images/bg-guest.png";
import GuestMobile from "../../assets/images/bg-guest-mobile.png";
import ClientTab from "../../assets/images/client-tab-landing.png";
import { updateIsSoonLanding } from "../../store/slicers/listingSlice";
import { useDispatch } from "react-redux";
const ClientLanding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isMobile, setMobile] = useState(navMobile);
  return (
    <div className="hidden-scroll h-screen overflow-hidden relative">
      <Helmet>
        <MetaTags
          title="An Events Marketplace"
          description="Undoubtedly personal. Create your own memories."
          keywords="genuine event merchants"
        />
      </Helmet>
      <div className="w-full absolute inset-0 flex justify-center flex-col ">
        <div className="flex p-10 md:p-24 flex-col justify-between h-full">
          <div className="flex flex-col h-full justify-start mt-5 md:justify-center">
            <div className="h-12">
              <div className="anyaya-new h-full w-full " />
            </div>
            <div
              className="my-8 md:my-12 flex flex-col gap-y-8 md:gap-y-14 text-5xl md:text-7xl"
              style={{ color: "#423767" }}
            >
              <div>
                <p>Events</p>
                <p>Marketplace</p>
              </div>
              <div className="text-lg md:text-2xl">
                <p>Connect with genuine event</p>
                <p>suppliers.</p>
              </div>
            </div>
            <button
              onClick={() => {
                // dispatch(updateIsSoonLanding(true));
                navigate("/category");
              }}
              className="w-40 md:w-56 bg-sunrise-500 text-white py-3 md:py-4 rounded-full hover:opacity-80"
            >
              <p className="text-base md:text-xl">Explore now</p>
            </button>
            <div
              className="block md:hidden mt-10   w-full self-center"
              style={{ color: "#423767" }}
            >
              <div className="text-xs md:text-sm">Powered by</div>
              <div className="h-5 w-18 mt-2">
                <div className="maya h-full w-full " />
              </div>
            </div>
          </div>

          <div
            className="hidden md:flex flex-row w-full self-center"
            style={{ color: "#423767" }}
          >
            <div className="text-sm mr-2">Powered by</div>
            <div className="h-6 w-20">
              <div className="maya h-full w-full " />
            </div>
          </div>
        </div>
      </div>

      <LazyLoadImage
        className="hidden lg:block h-screen w-full object-right object-contain bg-no-repeat bg-white"
        src={Guest}
        alt={"Client"}
      />
       <LazyLoadImage
        className="block md:block h-screen w-full object-bottom object-contain bg-no-repeat bg-white"
        src={ClientTab}
        alt={"ClientTab"}
      />
      <LazyLoadImage
        className="block md:hidden h-screen w-full object-bottom object-contain bg-no-repeat bg-white"
        src={GuestMobile}
        alt={"ClientMobile"}
      />
      {isMobile && <BetterBrowsing handleClose={() => setMobile(false)} />}
    </div>
  );
};

export default ClientLanding;
