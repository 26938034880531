import React, { useEffect, useRef, useState } from "react";
import MetaTags from "./MetaTags";
import Helmet from "react-helmet";
const Support = () => {
  const [activeTab, setActiveTab] = useState("0");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const proceedToEmail = () => {
    window.location.href = "mailto:support@anyaya-events.com";
  };
  const proceedToFacebook = () => {
    window.location.href = "https://www.facebook.com/anyaya.events";
  };
  return (
    <div className="px-8 lg:px-40 xl:px-56 pb-10 pt-6 align-center items-center ">
      <Helmet>
        <MetaTags
          title="Support"
          description="Support"
          keywords="genuine event merchants"
        />
      </Helmet>
      <div className="mt-5 text-justify">
        <div className="flex flex-row  border-b">
          <div
            onClick={() => setActiveTab("0")}
            className={`cursor-pointer text-xs lg:text-base text-left pb-2 mr-4 ${
              activeTab === "0" && "border-b border-primary-500"
            }`}
          >
            About Anyaya Platform
          </div>
          {/* <div
            onClick={() => setActiveTab("1")}
            className={`cursor-pointer text-xs lg:text-base text-left pb-2 mx-4 ${
              activeTab === "1" && "border-b border-primary-500"
            }`}
          >
            Reservation Status
          </div> */}
          <div
            onClick={() => setActiveTab("2")}
            className={`cursor-pointer text-xs lg:text-base text-left pb-2 mx-4 ${
              activeTab === "2" && "border-b border-primary-500"
            }`}
          >
            About Merchants & Security
          </div>
        </div>
      </div>

      {activeTab === "0" && (
        <div className="mt-5">
          <h1 className="mb-5 text-primary-500 text-lg font-semibold">
            Understanding About Anyaya
          </h1>
          <p className="text-xs lg:text-sm">
            Anyaya is an Events Marketplace that customers can access by
            subscription. We aim to directly connect customers seeking such
            services to capable event merchants. Anyaya only offers
            consumer-seller connection and does not partake in any transaction
            between the guest and merchant.
          </p>
          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Talk to the merchant first before booking.
          </h2>
          <p className="text-xs lg:text-sm">
            It is always best to confirm with the merchant their service and
            supply details. Simply check their listing details, ask questions
            and negotiate if you need additional service for your event. Our
            chat system is available 24/7.
          </p>
          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Confirm, Request, Reserve.
          </h2>
          <p className="text-xs lg:text-sm">
            <span className="font-semibold">Confirm</span> with the merchant if
            your needs will be met;{" "}
            <span className="font-semibold">Request</span> to book a date;{" "}
            <span className="font-semibold">Pay & Reserve</span> for your event
            directly to the merchant.
          </p>

          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Modify event details.
          </h2>
          <p className="text-xs lg:text-sm">
            When something came up and you have to reschedule your event, just
            simply notify the merchant for any changes in your plans.
          </p>
        </div>
      )}
      {/* {activeTab === "1" && (
        <div className="mt-5">
          <p className="text-xs lg:text-sm">
            Check your booking status from time to time to avoid unnecessary
            inconveniences regarding payments. Navigate here. Listed below are
            the indications of reservation status:
          </p>
          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Requested.
          </h2>
          <p className="text-xs lg:text-sm">
            This status indicates that you’ve already sent a request to book and
            it’s currently pending upon merchant’s approval.
          </p>
          <h2 className="mt-5 font-semibold text-sm lg:text-base">Accepted.</h2>
          <p className="text-xs lg:text-sm">
            This means the merchant has already accepted your request and you’re
            now able to pay for a reservation.
          </p>

          <h2 className="mt-5 font-semibold text-sm lg:text-base">Reserved.</h2>
          <p className="text-xs lg:text-sm">
            Your payment for reservation has been received and your event date
            is booked with the merchant until other payments are made.
          </p>

          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Confirmed.
          </h2> */}
      {/* <h2 className="mt-5 font-semibold text-sm lg:text-base">Booked.</h2> */}
      {/* <p className="text-xs lg:text-sm">
            This indicates full payment for the merchant’s service. Your event
            date is booked, and payments are verified and complete.
          </p>
        </div>
      )} */}

      {activeTab === "2" && (
        <div className="mt-5">
          <h2 className="font-semibold">Genuineness of the Merchants.</h2>
          <p className="text-xs lg:text-sm">
            Our mission is to provide guests/users connection with trusted
            merchants possessing exceptional event services. We make sure that
            all of our merchants bear the necessary permits to operate in the
            Republic of the Philippines. With that being said, still Anyaya will
            not have any liability regarding any damage that may occur during
            any event since we are not affiliated to any merchant in this
            platform.
          </p>
          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Diversification.
          </h2>
          <p className="text-xs lg:text-sm">
            One of our goals is to present a well-organized platform with
            variety, and we do this by filtering merchants down to uniqueness
            and supreme functionality.
          </p>

          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Payment Security and Convenience.
          </h2>
          <p className="text-xs lg:text-sm">
            Our payment system is powered by PayMaya Business. We prioritized
            our payment security intently and as such we make sure that your
            payment details when paying for a subscription, are delicately
            handled. However, Anyaya has no liability regarding any transactions
            made with any merchants. Customer and merchant negotiations are
            strictly confidential between the two parties.
          </p>

          <h2 className="mt-5 font-semibold text-sm lg:text-base">
            Anyaya Service Fee.
          </h2>
          <p className="text-xs lg:text-sm">
            Anyaya charges a very minimal subscription fee. This will allow our
            company to further operate, develop, and enhance our product to
            better serve our customers. We also reserve the right to alter our
            subscription fee any time and to cancel subscriptions that have been
            found to violate any agreement stated in the Terms & Conditions and
            Privacy Policy.
          </p>
        </div>
      )}

      <h2 className="mt-5 font-semibold text-sm lg:text-base">Contact us.</h2>
      <p className="text-xs lg:text-sm">
        If you need further assistance, kindly send us a message on facebook at
        <span
          className="text-primary-500 font-semibold cursor-pointer text-xs lg:text-sm text-left pb-2"
          onClick={() => proceedToFacebook()}
        >
          {" "}
          Anyaya Events Marketplace{" "}
        </span>
        or email us at
        <span
          className="text-primary-500 font-semibold cursor-pointer text-xs lg:text-sm text-left pb-2"
          onClick={() => proceedToEmail()}
        >
          {" "}
          support@anyaya-events.com{" "}
        </span>
      </p>
    </div>
  );
};

export default Support;
