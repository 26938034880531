import React from "react";

const MerchantTerms = () => {
  return (
    <div className="mt-5 text-xs lg:text-sm text-black">
      <div>
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          1. DEFINITIONS
        </div>
        <div className="pl-5 my-5">
          a. The Company, as the creator, operator, and publisher of the Website,
          makes the Website and certain Services on it, available to users.
          Anyaya Booking Services, Company, We, Us, Our, Ours and other first
          person pronouns will refer to the Company, as well as all employees
          and affiliates of the Company.
        </div>
        <div className="pl-5 my-5">
          b. You, as the user of the website will be referred to throughout this
          Agreement with second-person pronouns such as You, Your, Yours, or as
          User or Merchant.
        </div>
        <div className="pl-5 my-5">
          c. Collectively, the parties to this Agreement (the Company and You) will
          be referred to as Parties.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          2. INTELLECTUAL PROPERTY
        </div>
        <div className="mb-5">
          The Website, including all content features in the Website including
          all text, graphics, images, logos, trademarks, and the link (the
          "Content"), and Services provided by the Company are the property of
          the Company. You agree that that the Company owns all right, title,
          interest in and to the Content and that You will not use the Content
          for any unlawful or infringing purpose.
        </div>

        <div className="mb-5">
          Subject to this Agreement, the Company grants You a non-exclusive,
          limited, non-transferable, and revocable license to use the Content
          solely in connection with Your use of the Website and Services. The
          Content may not be used for any other purpose, and this license
          terminates upon Your cessation of the use of the Website or Services
          or at the termination of this Agreement.
        </div>

        <div>
          You agree not to reproduce or distribute the Content in any way,
          including electronically or via registration of any new trademarks,
          trade names, service marks, or Uniform Resource Locators (URLs),
          without express written permission from the Company.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          3. USER ACCOUNTS AND OBLIGATIONS
        </div>
          <p className="mb-5">
          Some content on the Website may only be accessed by the User by
          registering with Us. When You do so, You will choose a user
          identifier, which may be Your email address or another term, as well
          as a password. You may also be required to provide government
          identification, business permit, business information, billing
          information, and personal information, including, but not limited to,
          Your name. You are responsible for ensuring the accuracy of this
          information.
        </p>
          <p className="mb-5">
          You agree to keep Your user identifier and account log in details
          confidential and that You will not share such identifying information
          with any third party. If You discover that Your identifying
          information has been compromised, You agree to notify Us immediately
          in writing. Email notification will suffice.
        </p>
          <p className="mb-5">
          You are responsible for maintaining the safety and security of Your
          identifying, business, and billing information as well as keeping Us
          informed of any changes to Your identifying, business, and billing
          information. Providing false or inaccurate information, or using the
          Website or Services to further fraud or unlawful activity is grounds
          for immediate termination of this Agreement.
        </p>
          <p className="mb-5">
          You agree that You are solely responsible for all acts or omissions
          that occur under Your identifying, business, and billing information,
          including the content of any transmissions using the Website or
          Service.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black ">
          4. ACCEPTABLE USE
        </div>
        <div>
          As a condition of Your use of the Website or Services, You agree not
          to use the Website or Service for any unlawful purpose or any purpose
          prohibited under this clause. You agree not to use the Website or
          Services in any way that could damage the Website, Services, or
          general business of the Company.
        </div>
        <div className="mt-3">
          You further agree not to use the Website or Services to:
        </div>
        <div className="pl-5 my-5">
          a. Harass, abuse, or threaten others or otherwise violate any person's
          legal rights;
        </div>
        <div className="pl-5 my-5">
          b. Violate any intellectual property rights of the Company or any
          third party;
        </div>
        <div className="pl-5 my-5">c. Perpetrate any fraud;</div>
        <div className="pl-5 my-5">
          d. Engage in or create any unlawful gambling, sweepstakes, or pyramid
          scheme;
        </div>
        <div className="pl-5 my-5">
          e. Attempt to gain unauthorized access to the Website or Service,
          other accounts, computer systems or networks connected with the
          Website or Service;
        </div>
        <div className="pl-5 my-5">
          f. Transmit or upload any content or material that contains viruses,
          trojan horses, ransomware, or other harmful or deleterious programs or
          software;
        </div>
        <div className="pl-5 my-5">
          g. Publish or distribute any obscene or defamatory material;
        </div>
        <div className="pl-5 my-5">
          h. Publish or distribute any material that incites violence, date, or
          discrimination towards any group;
        </div>
        <div className="pl-5 my-5">
          i. Unlawfully gather information about others, including email
          addresses;
        </div>
        <div className="pl-5 my-5">
          j. Interfere with another user's use and enjoyment of the Website or
          Service or any similar Website or Service.
        </div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          5. SUBMISSION POLICY
        </div>
          <p className="mb-5">
          User Submissions are text, photos, images, graphics, videos, or any
          other content that the User and Merchant has added, uploaded, or
          posted to the Website or Service.
        </p>
          <p className="mb-5">
          All User Submissions shall remain the property of the User, unless
          otherwise stated, however, the User grants the Company a royalty-free,
          non-exclusive, perpetual, irrevocable, worldwide license to copy,
          display, use, broadcast, transmit, translate, distribute, modify, and
          make derivative works of any content You publish, upload, or otherwise
          make available to the Website, including your name and/or username,
          voice, and/or likeness, in whole or in part, in any media or
          technology.
        </p>
          <p className="mb-5">
          User Submissions are deemed non-confidential and the Company has no
          obligation to maintain its confidentiality.
        </p>
          <p className="mb-5">
          If You feel that any of Your intellectual property rights have been
          infringed or otherwise violated by the posting of information or media
          by another of Our users, please contact Us and let Us know.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          6. AFFILIATE MARKETING AND ADVERTISING
        </div>
          <p className="mb-5">
          The Company, through the Website and Services, may engage in affiliate
          marketing whereby the Company receives a commission on or percentage
          of the sale of goods or services on or through the Website. The
          Company may also accept advertising and sponsorships from commercial
          businesses or receive other forms of advertising compensation.
        </p>
          <p className="mb-5">
          Our acceptance of such advertising and sponsorships does not
          constitute Our endorsement, recommendation, or favoring of the
          advertised product or service.
        </p>
          <p className="mb-5">
          If You are an advertiser or a sponsor, You represent and warrant that
          You have all necessary rights and permissions to advertise or sponsor
          any product, service, or content displayed through the Website or
          Services and that Your advertisement or sponsorship complies with all
          applicable laws and regulations.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          7. THIRD PARTY LINKS
        </div>
          <p className="mb-5">
          You acknowledge that We may, from time to time, include links or
          references to other websites, other content, or other materials
          ("Third Party Links"), none of which are controlled by Us.
        </p>
          <p className="mb-5">
          All Third Party Links are provided for convenience only. We do not
          verify, warrant, endorse, or take responsibility for the content,
          accuracy, or other attributes of any Third Party Links. Accessing
          Third Party Links is at Your own risk.
        </p>
          <p className="mb-5">
          You further acknowledge and agree that We shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with the use of or reliance
          on any Third Party Links.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          8. SALE OF GOODS/SERVICES
        </div>
          <p className="mb-5">
          We sell Goods or Services, and allow You to sell goods or services on
          the Website, and therefore You should follow Our website’s trading and
          merchandising template.
        </p>
          <p className="mb-5">
          We may make changes to any Goods or Services offered on the Website,
          or to the applicable prices for any such Goods or Services, at any
          time, without notice. The materials on the Website with respect to
          Goods or Services may be out of date, and We make no commitment to
          update the materials on the Website with respect to such Goods or
          Services.
        </p>
          <p className="mb-5">
          The Company does not warrant that the quality of any products,
          services, information, or other material purchased or obtained by You
          will meet Your expectations, or that any errors in the Service will be
          corrected.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          9. EXCLUSION OF LIABILITY
        </div>
          <p className="mb-5">
          a. The Website and Service, and its Content, are provided for general
          information only and may change at any time without prior notice.
        </p>
          <p className="mb-5">
          b. The Company makes no representations or warranties of any kind,
          express or implied, about the completeness, accuracy, reliability,
          suitability, or availability with respect to the Website or the
          information, products, services, or related graphics contained on the
          Website for any purpose. Any reliance You place on such information is
          therefore strictly at Your own risk.
        </p>
          <p className="mb-5">
          c. In no event will the Company be liable for any loss or damage,
          including, without limitation, indirect or consequential loss or
          damage, or any loss or damage whatsoever arising from loss of data or
          profits arising out of, or in connection with, the use of the Website.
        </p>
          <p className="mb-5">
          d. The Company will not be liable for any loss or damage caused by a
          distributed denial-of-service attack, viruses, or other
          technologically harmful material that may infect Your computer
          equipment, computer programs, data, or other proprietary material due
          to Your use of the Website or to Your downloading of any material
          posted on it, or on any website linked to it.
        </p>
          <p className="mb-5">
          e. Any action You take upon the information You find on this Website
          is strictly at Your own risk.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          10. PAYMENT/BOOKING RESERVATION AND CONFIRMATION
        </div>
          <p className="mb-5">
          You agree to ensure payment for any services You may purchase from our
          services, and You acknowledge and affirm that prices are subject to
          change.
        </p>
          <p className="mb-5">
          You further agree to review and honor the terms and conditions of any
          particular transaction.
        </p>
          <p className="mb-5">
          You agree to be solely responsible for all payments, deposits, and
          cancellations or modification of booking made with Us.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          11. SERVICE RESERVATION, CANCELLATION, MODIFICATION, AND ACCURACY
        </div>
          <p className="mb-5">
          Our website is a subscription-based marketplace wherein Merchants and Customers can negotiate and freely transact outside the platform while absolving Us from any liability and damages. If in any case You would like to transact with an agreeing Customer using our website and payment solutions, such action should follow corresponding booking policies below: 
        </p>
          <p className="mb-5">
          We reserve the right to refuse any booking, reservation, or
          transaction for any reason at any time.
        </p>
          <p className="mb-5">
          We reserve the right to refuse to honor any incorrect bookings or
          offers stated on the Website, and/or cancel bookings if any incorrect
          bookings or offers were made on the Website.
        </p>
          <p className="mb-5">
          We reserve the right to modify the contents of the Website at any
          time, but We have no obligation to update any information on the
          Website. You agree that it is Your responsibility to monitor changes
          to the Website.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          12. ASSUMPTION OF RISK
        </div>
          <p className="mb-5">
          The Website and Services are provided for communication purposes and
          events booking services only.
        </p>
          <p className="mb-5">
          By using the Website or Service, You accept the risks associated with
          using the Internet. We shall not be liable for any loss of data or
          other damages that may result from Your use of the Website or Services
          or Your reliance on any information, materials, or Content available
          on the Website.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          13. PRIVACY
        </div>
          <p className="mb-5">
          Through Your Use of the Website and Services, You may provide Us with
          certain information.
        </p>
          <p className="mb-5">
          By using the Website or Services, You consent to Our collection and
          use of Your personal information as described in Our Privacy Policy.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          14. INDEMNITY
        </div>
          <p className="mb-5">
          You agree to defend and indemnify the Company and any of its
          affiliated (if applicable) and hold Us harmless against and legal
          claims and demands, including reasonable attorney's fees, which may
          arise from or relate to Your use or misuse of the Website or Services,
          Your breach of this Agreement, or Your conduct or actions.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          15. OTHER ACTIONS
        </div>
          <p className="mb-5">
          We reserve the right to take any of the following actions in Our sole
          discretion at any time and for any reason without giving You any prior
          notice:
        </p>
        <ul>
            <li className="mb-5">
            Restrict, suspend, or terminate Your access to all or any part of
            Our Services;
          </li>
            <li className="mb-5">
            Change, suspend, or discontinue all or any part of Our Services;
          </li>
            <li className="mb-5">
            Refuse, move, or remove any content that is available on Our
            Website;
          </li>
            <li className="mb-5">
            Deactivate or delete Your account and all related information and
            files in Your account;
          </li>
            <li className="mb-5">
            Establish general practices and limits concerning use of Our
            Website.
          </li>
        </ul>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          16. ENTIRE AGREEMENT
        </div>
          <p className="mb-5">
          This Agreement constitutes the entire understanding between the
          Parties with respect to any and all use of this Website. The Agreement
          supersedes and replaces all prior or contemporaneous agreements or
          understandings, written or oral, regarding the use of this Website.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          17. GOVERNING LAW AND JURISDICTION
        </div>
          <p className="mb-5">
          This Agreement and Your use of the Website and all non-contractual
          relationships arising out of Your use shall be governed and construed
          in accordance with the laws of the Philippines. In case of any dispute
          or litigation, the Parties agree to submit to the jurisdiction of the
          Philippines courts.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          18. DISPUTE RESOLUTION
        </div>
          <p className="mb-5">
          The Parties shall attempt to resolve any dispute arising out of or
          relating to this Agreement through friendly negotiations amongst the
          Parties. If the matter is not resolved through negotiation, the
          Parties agree to submit the dispute to mediation before resorting to
          arbitration or litigation.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          19. CHANGES TO THIS AGREEMENT
        </div>
          <p className="mb-5">
          The Company reserves the right to modify, amend, or update this
          Agreement at any time without prior notice. Any changes will be
          effective immediately upon posting on the Website. It is Your
          responsibility to review this Agreement periodically for updates.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          20. NOTICES
        </div>
          <p className="mb-5">
          Any notices required or permitted to be given under this Agreement
          shall be in writing and shall be delivered by email, certified mail,
          or courier service to the respective addresses provided by the
          Parties.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          21. SEVERABILITY
        </div>
          <p className="mb-5">
          If any provision of this Agreement is found to be invalid or
          unenforceable, such provision shall be severed from the Agreement, and
          the remaining provisions shall remain valid and enforceable to the
          fullest extent permitted by law.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          22. ASSIGNMENT
        </div>
          <p className="mb-5">
          This Agreement, or the rights granted hereunder, may not be assigned,
          sold, leased, or otherwise transferred in whole or in part by You
          without the prior written consent of the Company.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          23. NO WAIVER
        </div>
          <p className="mb-5">
          The failure of either Party to enforce any provision of this Agreement
          shall not be construed as a waiver of such provision or the right to
          enforce it at a later time.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          24. HEADINGS
        </div>
          <p className="mb-5">
          The headings of sections and subsections in this Agreement are for
          convenience only and shall not affect the interpretation of any
          provisions.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          25. CONTACT US
        </div>
        <div>
          You can contact us about this Agreement using the following details:
        </div>
        <div>Anyaya Booking Services</div>
        <div>Quezon City, Metro Manila, 1124.</div>
        <div>+639173002089</div>
        <div>support@anyaya-events.com</div>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          26. ACCEPTANCE OF THESE TERMS
        </div>
          <p className="mb-5">
          By using the Website or Services, you acknowledge that you have read,
          understood, and agree to be bound by all the terms and conditions of
          this Agreement.
        </p>
      </div>

      <div className="mt-5 text-xs lg:text-sm text-black">
        <div className="mb-2 text-sm lg:text-base font-semibold text-black">
          27. CHANGES TO TERMS AND CONDITIONS
        </div>
          <p className="mb-5">
          The Company reserves the right to change these terms and conditions at
          any time without prior notice. Your continued use of the Website or
          Services after any such changes constitutes Your acceptance of the new
          terms and conditions.
        </p>
      </div>
    </div>
  );
};

export default MerchantTerms;
