import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "./Menu";
import { useLocation, useNavigate } from "react-router-dom";
import {
  updateLoginModal,
  updateMenuModal,
} from "../../store/slicers/authSlice";
import { Logout } from "./Auth/Logout";
import { Menu as MenuIcon } from "../../assets/svg/Menu";
import Search from "../../assets/svg/Search";
import Back from "../../assets/svg/Back";
import Star from "../../assets/svg/Star";
import {
  updateFilterModalDate,
  updateFilterModalIsland,
  updateFilterModalLocation,
  updateFilterModalMerchant,
  updateListingLastPage,
  updateListingLoading,
  updateListingPage,
  updateListings,
  updateSearchModal,
  updateSearchNameInHome,
  updateSoonModal,
} from "../../store/slicers/listingSlice";
import { checkLocation, dateFormatter } from "../../utils/common";
import SearchComponent from "./Search";
import { MerchantEvents } from "../client/Listing/Events";
import AlbumModal from "../client/Listing/Gallery/AlbumModal";
import { SearchMobile } from "./SearchMobile";
import Loader from "./Loader";
import { config } from "../../utils/config";
import { toast } from "react-hot-toast";
import { UpdatePicture } from "./Auth/UpdatePicture";
import AnyayaMultiColor from "../../assets/svg/AnyayaMultiColor";
const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const merchantDetails = useSelector(
    (state) => state.auth.account_info?.merchant_info
  );

  const menuModal = useSelector((state) => state.auth.menuModal);
  const logoutModal = useSelector((state) => state.auth.logoutModal);

  const profileImage = useSelector(
    (state) => state.auth.account_info.profile_img
  );

  const firstName = useSelector(
    (state) => state.auth.account_info.firstname ?? "guest"
  );

  const merchantInfo = useSelector(
    (state) => state.auth.account_info.merchant_info
  );
  const isLoggedIn = useSelector((state) => state.auth.token);

  const isSearchModalOpen = useSelector(
    (state) => state.listing.isSearchModalOpen
  );
  const filterModalDate = useSelector((state) => state.listing.filterModalDate);
  const filterModalLocation = useSelector(
    (state) => state.listing.filterModalLocation
  );
  const filterModalMerchant = useSelector(
    (state) => state.listing.filterModalMerchant
  );
  const searchNameInHome = useSelector(
    (state) => state.listing.searchNameInHome
  );
  const selectedListing = useSelector((state) => state.listing.selectedListing);
  const [isSearchMobile, setIsSearchMobile] = useState(false);
  const [isLogoutOpen, setLogoutModal] = useState(false);
  const [isMerchantOpen, setMerchantModal] = useState(false);
  const [isOtpOpen, setOTPModal] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [isScrolledMenu, setScrolledMenu] = useState(false);
  const [isSearchShow, setSearchShow] = useState(false);
  const [isTopbarShow, setTopbar] = useState(false);
  const [isInCategory, setInCategory] = useState(false);
  const [isInListing, setInListing] = useState(false);
  const [isInInbox, setIsInInbox] = useState(false);
  const [searchText, setSearchText] = useState(searchNameInHome);
  const [albumModalOpen, setAlbumModalOpen] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [updateProfilePicture, setProfilePicture] = useState(false);
  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const servicePricing = useSelector((state) => state.listing.servicePricing);
  const selectedDate = useSelector((state) => state.listing.selectedDate);
  const [isPricingLoading, setPricingLoading] = useState(false);
  const [isInMerchant, setMerchantTopbar] = useState(false);
  const [isMerchantRegister, setMerchantRegister] = useState(false);
  const showWelcome = useSelector((state) => state.auth.showWelcome);

  const merchantMsg = useSelector((state) => state.auth.merchantMsg);
  const unreadNotif = useSelector((state) => state.auth.unreadNotif);
  const unreadMsg = useSelector((state) => state.auth.unreadMsg);

  const handleClick = () => {
    if (isInMerchant && merchantInfo) {
      window.location.href = "/merchant/home";
    } else {
      window.location.href = "/category";
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTopbar(false);
        break;
      case "/merchant":
        setTopbar(false);
        break;
      case "/category":
        setTopbar(true);
        setSearchShow(false);
        setInCategory(true);
        break;
      case "/merchant/home":
        setMerchantRegister(false);
        setMerchantTopbar(true);
        setTopbar(true);
        setSearchShow(false);
        setInCategory(false);
        break;
      case "/merchant/signup":
        setMerchantTopbar(true);
        setTopbar(true);
        setSearchShow(false);
        setInCategory(false);
        setMerchantRegister(true);
        break;
      case "/privacy-policy":
      case "/wishlists":
      case "/events":
      case "/events/reservations":
      case "/terms-and-conditions":
      case "/account":
      case "/profile":
      case "/account/payments":
      case "/account/personal":
      case "/account/login-and-security":
      case "/account/notifications":
      case "/notifications":
      case "/account/privacy-sharing":
      case "/support":
        setTopbar(true);
        setSearchShow(false);
        setInCategory(false);
        break;
      default:
        if (
          location.pathname.startsWith("/client/inbox/") ||
          location.pathname.startsWith("/events/") ||
          location.pathname.startsWith("/request/")
        ) {
          setTopbar(true);
          setSearchShow(false);
          setInCategory(false);
        } else {
          setTopbar(true);
          setSearchShow(true);
          setInCategory(false);
        }
    }

    // check if inside category show different search bar
    if (checkLocation("category/", location.pathname)) {
      setInListing(true);
      setSearchShow(false);
    } else {
      setInListing(false);
    }

    if (checkLocation("merchant", location.pathname)) {
      setMerchantTopbar(true);
      // setTopbar(true);
      setSearchShow(false);
      setInCategory(false);
    } else {
      setMerchantRegister(false);
      setMerchantTopbar(false);
    }
  }, [location]);

  useEffect(() => {
    setSearchText(searchNameInHome);
  }, [searchNameInHome]);

  useEffect(() => {
    const handlePopstate = () => {
      // Handle the popstate event here
      setScrolled(false); // Assuming setScrolled is a function to update the scroll state
    };

    // Add event listener for popstate
    window.addEventListener("popstate", handlePopstate);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []); // This effect runs only once on component mount

  useEffect(() => {
    if (isInListing) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      setScrolled(isMobile);
    }
  }, [location, isInListing]);

  useEffect(() => {
    setSearchText(searchNameInHome);
    setMerchantModal(false);
    if (location.pathname.includes("inbox")) {
      setIsInInbox(true);
    } else {
      setIsInInbox(false);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      const bottomOfPage = scrollHeight - clientHeight;

      if (isInListing) {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setScrolled(isMobile ? true : window.scrollY > 0);
      }

      setScrolledMenu(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, isInListing]);

  const searchListing = () => {
    dispatch(updateFilterModalLocation(""));
    dispatch(updateFilterModalMerchant(""));
    dispatch(updateFilterModalDate(""));
    dispatch(updateFilterModalIsland(""));

    if (searchText !== "") {
      navigate(`/category?search=${searchText}`);
    } else {
      navigate(`/category`);
    }

    dispatch(updateListingLoading(false));
    dispatch(updateListingLastPage(false));
    dispatch(updateListings([]));
    dispatch(updateListingPage("1"));
    dispatch(updateSearchNameInHome(searchText));
  };

  const otherEvents = () => {
    setMerchantModal(true);
  };

  useEffect(() => {
    const defaultDate = { startDate: "", endDate: "" };
    if (selectedDate["endDate"] === defaultDate["endDate"]) {
      setPricingLoading(true);
    } else {
      setPricingLoading(false);
    }
  }, [selectedDate]);

  var isMobile = window.matchMedia("(max-width: 1023px)").matches;
  const merchantActions = () => {};
  return (
    <>
      {!isScrolled && isTopbarShow && (
        <>
          <div
            className={`bg-white w-full ${
              isInInbox ? "px-0" : "px-0"
            } lg:px-40 xl:px-56`}
          >
            <div className="py-4">
              <div className="items-center justify-center">
                <div
                  id="logo"
                  className="flex items-center justify-center w-full h-12  cursor-pointer  border-b"
                >
                    <div
                    onClick={handleClick}
                    className=" h-full bg-center"
                  >
                    <AnyayaMultiColor />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <header
            className={`${
              isInInbox ? "px-10" : "px-10 py-2"
            } lg:px-40 xl:px-56 flex flex-row justify-center sticky top-0 bg-white  z-10`}
          >
            {!showWelcome && !isMerchantRegister && isInMerchant && (
              <div className="flex flex-row text-primary-600 flex-row gap-x-10 text-sm">
                <p
                  onClick={() => {
                    navigate("/merchant/home");
                  }}
                  className={`${
                    location.pathname === "/merchant/home" &&
                    "font-semibold lg:font-semibold border-b-2  border-sunrise-500"
                  } flex text-lg font-semibold lg:text-sm lg:font-normal py-2 hover:border-b-2 px-5 hover:border-sunrise-500 cursor-pointer`}
                >
                  Home
                </p>

                <p
                  onClick={() => {
                    navigate("/merchant/inbox/all");
                  }}
                  className={`${
                    location.pathname.startsWith("/merchant/inbox") &&
                    "font-semibold border-b-2 border-sunrise-500"
                  } hidden lg:flex  py-2 hover:border-b-2 px-5 hover:border-b hover:border-sunrise-500 cursor-pointer relative`}
                >
                  Inbox{" "}
                  {merchantMsg === 1 && (
                    <span className="bg-red-500 rounded-full p-0.5 absolute right-0"></span>
                  )}
                </p>
                <p
                  onClick={() => {
                    navigate("/merchant/calendar");
                  }}
                  className={`${
                    location.pathname === "/merchant/calendar" &&
                    "font-semibold border-b-2 px-4 border-sunrise-500"
                  }  hidden lg:flex  py-2 hover:border-b-2 px-5 hover:border-b hover:border-sunrise-500 cursor-pointer`}
                >
                  Calendar
                </p>
                <p
                  onClick={() => {
                    navigate("/merchant/insights");
                  }}
                  className={`${
                    location.pathname === "/merchant/insights" &&
                    "font-semibold border-b-2 px-4 border-sunrise-500"
                  } hidden lg:flex  py-2 hover:border-b-2 px-5 hover:border-b hover:border-sunrise-500 cursor-pointer`}
                >
                  Insights
                </p>
                {/* <p
                  onClick={() => {
                    navigate("/merchant/menu");
                  }}
                  className={`${
                    location.pathname === "/merchant/menu" &&
                    "font-semibold border-b-2 px-4 border-sunrise-500"
                  } hidden lg:flex  py-2 hover:border-b-2 px-5 hover:border-b hover:border-sunrise-500 cursor-pointer`}
                >
                  Menu
                </p> */}
              </div>
            )}

            {/* <div
              id="logo"
              className={`ml-6 flex lg:hidden items-center justify-center w-6 h-6 cursor-pointer relative ${
                isInCategory || isSearchShow || isInListing ? "hidden" : ""
              } lg:flex`}
            >
              {unreadNotif ? (
                <span className="bg-red-500 rounded-full p-0.5 absolute mx-1 top-0 right-0"></span>
              ) : (
                ""
              )}
              <div
                onClick={() => {
                  navigate("/notifications");
                }}
                className="bell h-full w-full"
              />
            </div> */}

            {!isSearchModalOpen && isSearchShow && (
              <div className="flex flex-row w-full">
                {/* <div
                  className="flex self-center lg:hidden"
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  <Back strokeWidth="20" className="w-6 h-6 mr-1" />
                </div> */}

                <div
                  onClick={isMobile ? () => setIsSearchMobile(true) : () => {}}
                  id="search"
                  className="cursor-pointer text-xs font-semibold select-none rounded-full ring-2 ring-primary-500 flex hover:shadow-lg w-60 lg:w-80 h-12"
                >
                  <div
                    className="text-center self-center w-2/6 px-2 whitespace-nowrap"
                    onClick={
                      isMobile
                        ? () => {}
                        : () =>
                            dispatch(
                              updateSearchModal({ status: true, type: "1" })
                            )
                    }
                  >
                    {filterModalLocation
                      ? filterModalLocation === "I'm Flexible"
                        ? filterModalLocation
                        : isMobile
                        ? filterModalLocation.substring(0, 5) +
                          (filterModalLocation.length > 5 ? "..." : "")
                        : filterModalLocation.substring(0, 5) +
                          (filterModalLocation.length > 9 ? "..." : "")
                      : "Location"}
                  </div>
                  <div className="border-r border-primary-500 my-2" />
                  <div
                    className="px-2 w-8/12 flex flex-row justify-between align-center items-center text-center self-center "
                    onClick={
                      isMobile
                        ? () => {}
                        : () =>
                            dispatch(
                              updateSearchModal({ status: true, type: "2" })
                            )
                    }
                  >
                    <div className="w-full lg:whitespace-nowrap text-xs">
                      {filterModalDate
                        ? isMobile
                          ? dateFormatter(filterModalDate).substring(0, 9) +
                            (dateFormatter(filterModalDate).length > 9
                              ? "..."
                              : "")
                          : dateFormatter(filterModalDate)
                        : "Date"}
                    </div>
                    <div className="rounded-full bg-primary-500 ">
                      <svg
                        style={{ padding: "1px" }}
                        color="#FFF"
                        viewBox="0 0 31 32"
                        width="32px"
                        height="32px"
                      >
                        <path
                          fill="#ffffff"
                          d="M 19 3 C 13.488281 3 9 7.488281 9 13 C 9 15.394531 9.839844 17.589844 11.25 19.3125 L 3.28125 27.28125 L 4.71875 28.71875 L 12.6875 20.75 C 14.410156 22.160156 16.605469 23 19 23 C 24.511719 23 29 18.511719 29 13 C 29 7.488281 24.511719 3 19 3 Z M 19 5 C 23.429688 5 27 8.570313 27 13 C 27 17.429688 23.429688 21 19 21 C 14.570313 21 11 17.429688 11 13 C 11 8.570313 14.570313 5 19 5 Z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isSearchModalOpen && (
              <div className="items-center  w-full hidden lg:flex">
                <div className="underline mr-5 text-sm text-black cursor-pointer">
                  Celebrate
                </div>
                <div className="cursor-pointer text-sm">Online Experience</div>
              </div>
            )}

            {isInListing || isInCategory ? (
              <div className="flex flex-row w-full">
                {isInListing && (
                  <div
                    className="flex self-center cursor-pointer"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <Back strokeWidth="20" className="w-6 h-6 mr-1" />
                  </div>
                )}
                {isInCategory && (
                  <div className="text-sm md:text-lg text-center flex justify-center">
                    <p className="self-center text-gray-500">
                      Welcome,{" "}
                      <span className="font-semibold">{firstName}!</span>
                    </p>
                  </div>
                )}
                {/* <div className="w-60 lg:w-80 cursor-pointer text-xs select-none rounded-full ring-2 ring-primary-500 flex hover:shadow-lg">
                  <div className="items-center py-2  flex-row font-semibold flex w-full">
                    <input
                      autoFocus
                      value={searchText}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchListing();
                        }
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      placeholder="Search Anything"
                      className="h-full placeholder-primary-500 bg-transparent appearance-none peer outline-none w-full  px-4"
                    />
                    <div
                      onClick={() => searchListing()}
                      className="rounded-full bg-primary-500 mr-2"
                    >
                      <Search />
                    </div>
                  </div>
                </div> */}
              </div>
            ) : null}

            <div
              className="items-center flex w-full justify-end"
              id="right-side"
            >
              {!isMerchantRegister && !isInMerchant && (
                <div
                  id="diy"
                  className="cursor-pointer border-primary-500 font-semibold border mx-2 hidden lg:flex confetti-bg text-primary-500 text-sm w-max p-4 rounded-full hover:opacity-80 select-none"
                  onClick={() => {
                    window.open(
                      "https://heyzine.com/flip-book/fe3b4e9a2e.html",
                      "_blank"
                    );
                  }}
                >
                  Make it DIY!
                </div>
              )}
              {isMerchantRegister && (
                <div
                  id="event-guest"
                  className="cursor-pointer hidden lg:flex  bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none px-5"
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Switch as Guest
                </div>
              )}

              {isInMerchant && merchantInfo && (
                <div
                  id="event-guest"
                  className="cursor-pointer hidden lg:flex  bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none px-5"
                  onClick={() => {
                    navigate("/category");
                  }}
                >
                  Switch as Guest
                </div>
              )}
              {!isInMerchant && merchantInfo && (
                <div
                  id="event-merchant"
                  className="cursor-pointer hidden lg:flex  bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none px-5"
                  onClick={() => {
                    navigate("/merchant/home");
                  }}
                >
                  Switch as Merchant
                </div>
              )}

              {!isInMerchant && !merchantInfo && (
                <div
                  id="event-merchant"
                  className="cursor-pointer hidden lg:flex  bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none"
                  onClick={() => {
                    navigate("/merchant");
                  }}
                >
                  Be an Event Merchant
                </div>
              )}
              <button
                id="menu"
                className="ml-1 p-2 focus:outline-none rounded-md hover:opacity-60 relative"
                onClick={() => {
                  dispatch(updateMenuModal(true));
                }}
              >
                {isInMerchant
                  ? (merchantMsg === 1 || unreadNotif === 1) && (
                      <span className="bg-red-500 rounded-full p-0.5 absolute right-0"></span>
                    )
                  : (unreadMsg === 1 || unreadNotif === 1) && (
                      <span className="bg-red-500 rounded-full p-0.5 absolute right-0"></span>
                    )}
                <MenuIcon />
              </button>
              {/* {isLoggedIn && ( */}
              <div id="user" className="hidden lg:flex">
                <div className="w-10 h-10">
                  {profileImage ? (
                    <div
                      onClick={() => {
                        if (isLoggedIn) {
                          setProfilePicture(true);
                        }
                      }}
                      className="rounded-full bg-primary-500 w-full h-full bg-no-repeat bg-cover"
                      style={{ backgroundImage: `url(${profileImage})` }}
                    ></div>
                  ) : (
                    <div
                      onClick={() => {
                        if (isLoggedIn) {
                          setProfilePicture(true);
                        } else {
                          dispatch(updateMenuModal(true));
                        }
                      }}
                      className="rounded-full defaulticon w-full h-full bg-no-repeat bg-cover"
                    ></div>
                  )}
                </div>
              </div>
              {/* )}  */}
            </div>
          </header>
        </>
      )}
      {isScrolled && (
        <>
          {" "}
          <div
            className={`bg-white w-full ${
              isInInbox ? "px-0" : "px-0"
            } lg:px-40 xl:px-56`}
          >
            <div className="py-4">
              <div className="items-center justify-center">
                <div
                  id="logo"
                  className="flex items-center justify-center w-full h-12  cursor-pointer  border-b"
                >
                  <div
                    onClick={handleClick}
                    className=" h-full bg-center"
                  >
                    <AnyayaMultiColor />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <header className="border-b lg:border-b-0 bg-white sticky z-10 top-0 w-full">
            <nav className="px-10 lg:px-40 xl:px-56 py-4">
              <div className="flex flex-row items-center justify-between">
                <div className="gap-x-5 text-xs flex flex-row cursor-pointer hidden md:flex">
                  <p
                    className="bg-primary-500  p-4 rounded-full text-white rounded-full hover:opacity-8 "
                    onClick={() => {
                      setAlbumModalOpen(true);
                      setHideModal(false);
                    }}
                  >
                    Photos
                  </p>
                  <p
                    className="bg-primary-500  p-4 rounded-full text-white rounded-full hover:opacity-8 "
                    onClick={() => otherEvents()}
                  >
                    Other Events
                  </p>
                </div>

                <div className="text-base text-gray-500 block md:hidden">
                  <div className="flex  flex-col justify-between">
                    <p className="font-semibold text-black text-base">
                      {isPricingLoading ? (
                        <div className="mb-1">
                          <Loader borderColor="border-primary-500" />
                        </div>
                      ) : servicePricing?.service_price ? (
                        "PHP " + servicePricing?.service_price
                      ) : (
                        "PHP " + 0.0
                      )}
                    </p>
                    <p className="font-semibold text-sm">Contract Price</p>
                  </div>
                </div>

                <div className="py-2">
                  <div className="flex flex-row justify-between ">
                    {serviceOffered.star_rank && (
                      <div className="text-xs  flex flex-row items-center mr-4  hidden lg:flex">
                        <Star className="mr-2" />
                        {serviceOffered.star_rank} |
                        <p className="text-xs ml-1 font-semibold text-sunrise-500  hidden lg:flex">
                          {serviceOffered.review_count}{" "}
                          {Number(serviceOffered.review_count) > 1
                            ? "stars"
                            : "star"}
                        </p>
                      </div>
                    )}

                    {!isPricingLoading && (
                      <div
                        id="event-merchant"
                        className="cursor-pointer flex bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none"
                        onClick={() => {
                          // if(merchantDetails?.merchant_code === serviceOffered?.merchant_code) {
                          //   navigate(-1)
                          // } else {
                          //   dispatch(updateSoonModal(true));
                          // }

                          if (isLoggedIn) {
                            if (
                              merchantDetails?.merchant_code ===
                              serviceOffered?.merchant_code
                            ) {
                              navigate(-1);
                            } else {
                              const extractedData = pathnameParts.slice(2, 5);
                              const result = extractedData.join("/");
                              window.open(
                                config.ANYAYA_URL +
                                  `/request/${result}?start_date=${selectedDate?.startDate}&end_date=${selectedDate?.endDate}`,
                                "_blank"
                              );
                            }
                          } else {
                            dispatch(updateLoginModal({ status: true }));
                          }
                        }}
                      >
                        {merchantDetails?.merchant_code ===
                        serviceOffered?.merchant_code
                          ? "Exit Listing View"
                          : "Chat with Merchant"}
                      </div>

                      // <button

                      //   className="bg-primary-500 px-auto text-white font-semibold py-3 rounded-full hover:opacity-90"
                      // >
                      //   <p className="text-xs mx-2">Send a Booking Request</p>
                      // </button>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </>
      )}
      <AlbumModal
        hideModal={hideModal}
        setHideModal={(status) => {
          setHideModal(status);
        }}
        albumModalOpen={albumModalOpen}
        setAlbumModalOpen={(status) => {
          setAlbumModalOpen(status);
        }}
      />

      {isSearchMobile && (
        <SearchMobile
          isOpen={isSearchMobile}
          onClose={() => {
            setIsSearchMobile(false);
          }}
        />
      )}

      {isSearchModalOpen && <SearchComponent />}
      {isMerchantOpen && (
        <MerchantEvents
          isOpen={isMerchantOpen}
          onClose={() => {
            setMerchantModal(false);
          }}
        />
      )}
      {menuModal && (
        <Menu
          isScrolled={isScrolledMenu}
          isMerchantRegister={isMerchantRegister}
          isInMerchant={isInMerchant}
          merchantInfo={merchantInfo}
        />
      )}
      {logoutModal && <Logout />}
      {updateProfilePicture && (
        <UpdatePicture setProfilePicture={() => setProfilePicture(false)} />
      )}
    </>
  );
};

export default Topbar;
