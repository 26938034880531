import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewListing } from "../../../api/client/listing";
import {
  updateMultipleFields,
  updateSoonModal,
} from "../../../store/slicers/listingSlice";
import ContentLoader from "react-content-loader";
import {
  convertToKebabCase,
  findEarliestAvailableDate,
  formatDateToMonthYear,
} from "../../../utils/common";
import MetaTags from "../../common/MetaTags";
import Star from "../../../assets/svg/Star";
import Loader from "../../common/Loader";
import { updateLoginModal } from "../../../store/slicers/authSlice";
import ReactHtmlParser from 'react-html-parser';

import CalendarService from "./Calendar";
import Gallery from "./Gallery";
import { ShareAndSave } from "../../common/ShareAndSave";
import { Review } from "./Reviews";
import { ReportListing } from "./Report";
import { Highlights } from "./Highlights";
import { config } from "../../../utils/config";
import { chatMerchant } from "../../../api/merchant/chat";
import { chatClient } from "../../../api/client/chat";
import { ProfileDetails } from "../Inbox/ProfileDetails";
import { MerchantEvents } from "./Events";
import Helmet from "react-helmet";
import { Soon } from "./Soon";
import { GiveStar } from "./Star";
const Service = () => {
  const isLoggedIn = useSelector((state) => state.auth.token);
  const merchantDetails = useSelector(
    (state) => state.auth.account_info?.merchant_info
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathnameParts = location.pathname.split("/");
  const listingId = pathnameParts[pathnameParts.length - 1];
  const [isLoading, setIsLoading] = useState(true);

  const [showReport, setShowReport] = useState(false);
  const [giveStar, setShowGiveStar] = useState(false);
  const [isReviewsOpen, setReviews] = useState(false);
  const [isHighlightsOpen, setHighlights] = useState(false);

  const [isPricingLoading, setPricingLoading] = useState(false);

  const serviceCalendar = useSelector((state) => state.listing.serviceCalendar);
  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const servicePricing = useSelector((state) => state.listing.servicePricing);
  const serviceReviews = useSelector((state) => state.listing.serviceReviews);
  const eventHighlights = useSelector((state) => state.listing.eventHighlights);
  const selectedDate = useSelector((state) => state.listing.selectedDate);
  const [stateChanger, setState] = useState("");
  const [merchantProfile, setMerchantProfile] = useState(false);
  const [isMerchantOpen, setMerchantModal] = useState(false);
  const handleInit = async () => {
    setIsLoading(true);
    try {
      const _requestData = {
        service_code: listingId,
      };
      const response = await viewListing(_requestData);
      if (response.error === 0) {
        const data = response.data;
        dispatch(
          updateMultipleFields({
            serviceCalendar: data.service_calendar,
            serviceOffered: data.service_offered,
            servicePricing: data.service_pricing,
            servicePhotos: data.service_photos,
            serviceReviews: data.service_reviews,
            eventHighlights: data.event_highlight,
          })
        );
        setIsLoading(false);
      } else {
        dispatch(
          updateMultipleFields({
            serviceCalendar: [],
            serviceOffered: {},
            servicePricing: {},
            servicePhotos: [],
            serviceReviews: [],
            eventHighlights: [],
          })
        );
        setIsLoading(false);
        navigate(-1);
      }
    } catch (error) {
      setIsLoading(false);
      navigate(-1);
    }
  };

  useEffect(() => {
    handleInit();
    const earliestAvail = findEarliestAvailableDate(serviceCalendar);
    dispatch(
      updateMultipleFields({
        selectedDate: {
          startDate: earliestAvail,
          endDate: earliestAvail,
        },
      })
    );
  }, []);

  useEffect(() => {
    setState("s");
  }, [isLoggedIn]);

  const handleNavigation = async () => {
    // Construct the URL with the desired parameters
    const url = `/client/inbox/${serviceOffered?.merchant_code}`;

    const _requestData = {
      id: serviceOffered?.merchant_code,
      flag: "send_inquiry",
      content: "Hello",
    };
    try {
      const response = await chatClient(_requestData);
      if (response.error === 0) {
        navigate(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const moreHighlights = () => {
    setHighlights(true);
  };

  const otherEvents = () => {
    setMerchantModal(true);
  };

  useEffect(() => {
    const defaultDate = { startDate: "", endDate: "" };
    if (selectedDate["endDate"] === defaultDate["endDate"]) {
      setPricingLoading(true);
    } else {
      setPricingLoading(false);
    }
  }, [selectedDate]);
  return (
    <>
      {isLoading ? (
        <div className="px-10 lg:px-40 xl:px-56 pb-10 pt-6 flex w-full h-full">
          <ContentLoader
            speed={2}
            width="100%"
            height="100%"
            viewBox="0 0 1080 650"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <circle cx="380" cy="400" r="30" />
            <rect x="7" y="8" rx="10" ry="10" width="350" height="40" />
            <rect x="7" y="60" rx="10" ry="10" width="530" height="300" />

            <rect x="545" y="60" rx="10" ry="10" width="280" height="145" />
            <rect x="545" y="210" rx="10" ry="10" width="280" height="145" />
            <rect x="830" y="60" rx="10" ry="10" width="280" height="145" />
            <rect x="830" y="210" rx="10" ry="10" width="280" height="145" />

            <rect x="7" y="370" rx="5" ry="5" width="250" height="10" />
            <rect x="7" y="390" rx="10" ry="10" width="250" height="10" />
            <rect x="7" y="460" rx="10" ry="10" width="500" height="20" />
            <rect x="7" y="490" rx="10" ry="10" width="500" height="20" />
            <rect x="7" y="520" rx="10" ry="10" width="500" height="20" />

            <rect x="740" y="370" rx="10" ry="10" width="340" height="400" />
          </ContentLoader>
        </div>
      ) : (
        <div className="px-10 lg:px-40 xl:px-56 pb-10 pt-4 min-h-screen flex flex-col">
          <Helmet>
            <MetaTags
              title={serviceOffered.title}
              description={serviceOffered.about}
              keywords={
                serviceOffered.full_name +
                " " +
                serviceOffered.municipality +
                " " +
                serviceOffered.province
              }
              imageUrl={serviceOffered.cover_img}
            />
          </Helmet>
          <main className="">
            <div className="pt-2 flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col lg:flex-row">
                <div className="text-xl lg:text-3xl font-semibold text-primary-500 mr-4">
                  {serviceOffered.title}{" "}
                  <span className="text-gray-500">by</span>{" "}
                  {serviceOffered.merchant_name}
                </div>
                {/* {serviceOffered.star_rank && ( */}
                <div className="lg:p-1 text-sm font-semibold mt-2 flex flex-wrap lg:self-end lg:mt-0">
                  {/* <Star className="mr-2" />
                    {serviceOffered.star_rank} |
                    <div className="text-sm ml-1 mr-2 font-semibold text-sunrise-500">
                      {serviceOffered.review_count}{" "}
                      {Number(serviceOffered.review_count) > 1
                        ? "stars"
                        : "star"}
                    </div> */}
                  <div className="text-sm">
                    {serviceOffered.municipality}, {serviceOffered.province}
                  </div>
                </div>
                {/* )} */}
              </div>

              <div className="mt-2 lg:self-end lg:mt-0">
                <ShareAndSave />
              </div>
            </div>
            <div className="text-lg">{serviceOffered?.subtitle}</div>
            <Gallery />

            <div className="pr-4 md:pr-0 md:grid md:grid-cols-12 md:gap-x-12">
              <div className="md:col-span-8">
                <div className="flex flex-row mt-5 justify-between">
                  <div className="flex flex-col w-full">
                    <div
                      className="flex flex-row"
                      onClick={() => setMerchantProfile(true)}
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-wrap text-xl md:text-3xl mr-4">
                          <div className="mr-2">Merchant: </div>
                          <div className="text-primary-500 cursor-pointer">
                            {serviceOffered.full_name}
                          </div>
                        </div>
                        {serviceOffered.review_summary && (
                          <div className="flex flex-row text-xs mt-1 items-center">
                            <div className="w-5 h-5 mr-2">
                              <div className="rating w-full h-full bg-no-repeat bg-contain"></div>
                            </div>
                            <div>{serviceOffered.review_summary}</div>
                          </div>
                        )}
                      </div>
                      <div className="hidden mr-2 md:block p-3 text-white">
                        {serviceOffered.profile_img !== "" ? (
                          <img
                            draggable="false"
                            id="merchant-image"
                            className="rounded-full bg-primary-500 w-14 h-14 bg-no-repeat bg-cover"
                            src={serviceOffered.profile_img}
                            alt={serviceOffered.full_name}
                          />
                        ) : (
                          <div className="rounded-full defaulticon w-14 h-14 bg-no-repeat bg-cover"></div>
                        )}
                      </div>
                    </div>

                    <div id="merchant_other_details" className="py-8 border-b ">
                      <div className="w-full md:w-3/4">
                        {!serviceOffered?.sub_info?.payment_scheme
                          ? null
                          : serviceOffered?.sub_info?.payment_scheme?.length !==
                              0 && (
                              <div className="flex flex-row items-center">
                                <div className="w-10 h-10 mr-3">
                                  <div className="money w-full h-full bg-no-repeat bg-contain"></div>
                                </div>
                                <div className="w-full">
                                  <div className="text-sm text-black">
                                    Merchant's Payment Scheme
                                  </div>
                                  <div className="text-gray-400 text-xs lg:text-sm">
                                    {serviceOffered?.sub_info?.payment_scheme?.map(
                                      (scheme, key) => {
                                        return <p key={key}>{scheme}</p>;
                                      }
                                    )}
                                    {/* <p>
                                      Pay reservation fee within 24hrs to secure
                                      the date.
                                    </p> */}
                                  </div>
                                </div>
                              </div>
                            )}

                        {/* <div className="mt-5 flex flex-row  items-center">
                          <div className="w-10 h-10 mr-3">
                            <div className="secured w-full h-full bg-no-repeat bg-contain"></div>
                          </div>
                          <div className="w-full">
                            <div className="text-sm text-black">Secured</div>
                            <div className="text-gray-400 text-xs lg:text-sm">
                              Our payment system prioritizes security and
                              safety. We ensure payment details and transactions
                              are protected.
                            </div>
                          </div>
                        </div> */}

                        {serviceOffered?.sub_info?.recognized !== "" && (
                          <div className="mt-5 flex flex-row  items-center">
                            <div className="w-10 h-10 mr-3">
                              <div className="recognized w-full h-full bg-no-repeat bg-contain"></div>
                            </div>
                            <div className="w-full">
                              <div className="text-sm text-black">
                                Recognized
                              </div>
                              <div className="text-gray-400 text-xs lg:text-sm">
                                {serviceOffered?.sub_info?.recognized}
                              </div>
                            </div>
                          </div>
                        )}

                        {serviceOffered?.sub_info?.response_rate !== "" && (
                          <div className="mt-5 flex flex-row  items-center">
                            <div className="w-10 h-10 mr-3">
                              <div className="response w-full h-full bg-no-repeat bg-contain"></div>
                            </div>
                            <div className="w-full">
                              <div className="text-sm text-black">
                                Response Rate
                              </div>
                              <div className="text-gray-400 text-xs lg:text-sm">
                                {serviceOffered?.sub_info?.response_rate}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {eventHighlights.length !== 0 && (
                      <div id="merchant-highlights" className="">
                        <div className="py-8 font-semibold text-black text-sm lg:text-base">
                          The Highlights
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2">
                          {eventHighlights.slice(0, 3).map((highlight, key) => {
                            return (
                              <div key={key}>
                                <img
                                  draggable={false}
                                  className={`${
                                    key === 0
                                      ? "md:rounded-none md:rounded-l-3xl"
                                      : key === 1
                                      ? "rounded-3xl md:rounded-none"
                                      : "md:rounded-none md:rounded-r-3xl"
                                  } h-36 xl:h-44 bg-sunrise-500 rounded-3xl `}
                                  src={highlight.img_name}
                                  alt={highlight.title}
                                />
                                <div className="text-xs underline text-center mt-2 text-black">
                                  {highlight.title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {eventHighlights.length > 3 && (
                          <div
                            onClick={() => moreHighlights()}
                            className="text-xs text-primary-500 float-right cursor-pointer mt-2"
                          >
                            View More
                          </div>
                        )}
                      </div>
                    )}

                    {/* supply detail */}
                    {serviceOffered?.supply_detail &&
                      Object.keys(serviceOffered.supply_detail).length !==
                        0 && (
                        <div id="merchant_supply_details" className="py-8 ">
                          <div className="text-black font-semibold">
                            Merchant's Supply Details
                          </div>
                          <div className="w-full md:w-3/4">
                           {serviceOffered.supply_detail.theme && <div className="mt-5 flex flex-row  items-center">
                              <div className="w-10 h-10 mr-3">
                                <div className="eventtheme w-full h-full bg-no-repeat bg-contain"></div>
                              </div>
                              <div className="w-full">
                                <div className="text-sm text-black">
                                  Type of Event
                                </div>
                                <div className="text-gray-400 text-xs lg:text-sm">
                                  {serviceOffered.supply_detail.theme}
                                </div>
                              </div>
                            </div>}

                            <div className="flex mt-5 flex-wrap gap-y-5 justify-between">
                              <div className="flex flex-row  items-center">
                                <div className="w-10 h-10 mr-3">
                                  <div className="servicecap w-full h-full bg-no-repeat bg-contain"></div>
                                </div>
                                <div className="w-full">
                                  <div className="text-sm text-black">
                                    Guest Supply Capacity
                                  </div>
                                  <div className="text-gray-400 text-xs lg:text-sm">
                                    {serviceOffered.supply_detail.pax} (number
                                    of guests)
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-row  items-center">
                                <div className="w-10 h-10 mr-3">
                                  <div className="timelimit w-full h-full bg-no-repeat bg-contain"></div>
                                </div>
                                <div className="w-full">
                                  <div className="text-sm text-black">
                                    Service Time Limit
                                  </div>
                                  <div className="text-gray-400 text-xs lg:text-sm">
                                    {serviceOffered.supply_detail.time_limit}{" "}
                                    (Hours of Service)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {/* out of town fee */}
                    {serviceOffered.outside_town_fee === "1" && (
                      <div className="text-xs italic ">
                        This listing has an out of town fee. Please talk to the
                        merchant.
                      </div>
                    )}

                    {/* about */}
                    {serviceOffered.about && (
                      <div id="about" className="pt-8 ">
                        <div className="font-semibold text-black text-sm lg:text-base">
                          About
                        </div>
                        <div className="text-gray-400 text-xs lg:text-sm text-justify">
                          {ReactHtmlParser(serviceOffered.about)}
                        </div>
                      </div>
                    )}

                    {/* special_inclusion */}
                    {serviceOffered.special_inclusions && (
                      <div id="special_inclusion" className="border-b py-8 ">
                        <div className="font-semibold text-black text-sm lg:text-base">
                          Special Inclusions
                        </div>
                        <div className="text-gray-400 text-xs lg:text-sm text-justify">
                          {ReactHtmlParser(serviceOffered.special_inclusions)}
                        </div>
                      </div>
                    )}
                    {/* cursor-pointer flex bg-primary-500 text-white text-sm w-max p-4 rounded-full hover:opacity-80 select-none */}
                    <div
                      onClick={() => otherEvents()}
                      id="other_events"
                      className="block rounded-full p-4 lg:hidden text-white border-b mt-5 bg-primary-500  cursor-pointer hover:opacity-80"
                    >
                      <div className="font-semibold  text-sm lg:text-base">
                        Other Events
                      </div>
                      <div className="hidden lg:flex text-xs lg:text-sm text-justify">
                        Check out other event listings of the merchant.
                      </div>
                    </div>

                    <div id="calendar" className="text-center-webkit my-10">
                      <CalendarService dateBlocked={serviceCalendar} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:block col-span-4">
                <div className="sticky top-32 mt-5">
                  <div className="border border-primary-500 rounded-3xl p-5 2xl:p-10 bg-white">
                    <div className="flex flex-wrap gap-2 justify-between">
                      <div>
                        <p className="text-base font-semibold 2xl:text-xl">
                          {merchantDetails?.merchant_code ===
                          serviceOffered?.merchant_code
                            ? "Payout Details"
                            : "Payment Details"}
                        </p>
                        {serviceOffered.pax && (
                          <p className="self-center mt-1 text-2xs italic">
                            maximum of {serviceOffered.pax} pax
                          </p>
                        )}
                      </div>

                      {/* {serviceOffered.star_rank && (
                        <div
                          className={`${
                            serviceOffered.pax && "mt-2"
                          } items-center text-xs lg:text-sm  flex flex-wrap`}
                        >
                          <Star className="mr-2" height={12} width={12} />
                          {serviceOffered.star_rank} |
                          <div className="text-xs lg:text-sm ml-1 mr-2 font-semibold text-sunrise-500">
                            {serviceOffered.review_count}{" "}
                            {Number(serviceOffered.review_count) > 1
                              ? "stars"
                              : "star"}
                          </div>
                        </div>
                      )} */}
                    </div>

                    {isPricingLoading && (
                      <div className="mt-5">
                        <Loader borderColor="border-primary-500" />
                      </div>
                    )}

                    {!isPricingLoading && (
                      <div className="border mt-5 rounded-3xl">
                        <div className="text-sm text-gray-500 border-b">
                          <div className="flex flex-wrap justify-between p-3 items-center">
                            <div>Contract Price</div>
                            <div>{servicePricing.service_price} PHP</div>
                          </div>
                        </div>
                        <div className="text-sm text-gray-500 border-b">
                          <div className="flex flex-wrap justify-between p-3">
                            <div>
                              Reservation Fee
                              <div className="text-2xs">
                                Deductible from the Contract Price
                              </div>
                            </div>
                            <div>{servicePricing.reservation_fee} PHP</div>
                          </div>
                        </div>
                        {servicePricing.total_surcharge !== "0.00" && (
                          <div className="text-sm text-gray-500 border-b">
                            <div className="flex flex-wrap justify-between p-3">
                              <div>Surcharge</div>
                              <div>{servicePricing.total_surcharge} PHP</div>
                            </div>
                          </div>
                        )}
                        {/* <div className="text-sm text-gray-500 border-b">
                          <div className="flex flex-wrap justify-between p-3">
                            <div>{merchantDetails?.merchant_code === serviceOffered?.merchant_code ? 'Merchant Service Fee' : 'Customer Service Fee'}</div>
                            <div>{merchantDetails?.merchant_code === serviceOffered?.merchant_code ? servicePricing.merchant_service_fee : servicePricing.service_fee} PHP</div>
                          </div>
                        </div> */}
                        <div className="bg-sunrise-500 rounded-b-3xl text-sm text-white">
                          <div className="flex font-semibold text-white flex-wrap justify-center p-3">
                            <div>{servicePricing.net_amount} PHP</div>
                          </div>
                        </div>
                      </div>
                    )}

                    <button
                      onClick={() => {
                        // if(merchantDetails?.merchant_code === serviceOffered?.merchant_code) {
                        //   navigate(-1)
                        // } else {
                        //   dispatch(updateSoonModal(true));
                        // }
                        if (
                          merchantDetails?.merchant_code ===
                          serviceOffered?.merchant_code
                        ) {
                          navigate(-1);
                        } else {
                          if (!isPricingLoading) {
                            if (isLoggedIn) {
                              const extractedData = pathnameParts.slice(2, 5);
                              const result = extractedData.join("/");
                              window.open(
                                config.ANYAYA_URL +
                                  `/request/${result}?start_date=${selectedDate?.startDate}&end_date=${selectedDate?.endDate}`,
                                "_blank"
                              );
                            } else {
                              dispatch(updateLoginModal({ status: true }));
                            }
                          }
                        }
                      }}
                      className="mt-10 bg-primary-500 w-full text-white font-semibold py-3 rounded-lg hover:opacity-90"
                    >
                      {isPricingLoading ? (
                        <Loader />
                      ) : (
                        <p className="text-sm">
                          {merchantDetails?.merchant_code ===
                          serviceOffered?.merchant_code
                            ? "Exit Listing View"
                            : "Chat with Merchant"}
                        </p>
                      )}
                    </button>
                    {/* {merchantDetails?.merchant_code !== serviceOffered?.merchant_code && <p className="text-xs text-gray-400 mt-4 font-light w-full text-center">
                      You won't get charged yet. Request a booking to chat with
                      the merchant. You can cancel your request anytime.
                    </p>} */}
                  </div>
                  {/* <button
                    onClick={() => {
                      if (isLoggedIn) {
                        handleNavigation();
                      } else {
                        dispatch(updateLoginModal({ status: true }));
                      }
                    }}
                    className="cursor-pointer mt-4 border border-primary-500 w-full text-primary-500 font-semibold py-3 rounded-lg hover:text-primary-100 hover:border-primary-100"
                  >
                    <p className="text-sm">Contact Merchant</p>
                  </button> */}
                  <div className="flex flex-row mt-8">
                    <div
                      className="items-center flex flex-row text-xs font-light w-full underline cursor-pointer"
                      onClick={() => {
                        if (isLoggedIn) {
                          setShowReport(true);
                        } else {
                          dispatch(updateLoginModal({ status: true }));
                        }
                      }}
                    >
                      Report Listing
                      <div className="w-4 h-4 ml-1">
                        <div className="report w-full h-full bg-no-repeat bg-contain"></div>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        setShowGiveStar(true);
                      }}
                      className="bg-primary-500 w-full text-white font-semibold py-3 rounded-lg hover:opacity-90"
                    >
                      <p className="text-sm">Give this listing a star</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="reviews" className="pr-4 md:pr-0 py-5 mt-5 border-t">
                {/* {serviceOffered.star_rank && (
                  <div className="lg:p-1 text-xs  mt-2 flex lg:self-end lg:mt-0">
                    <Star className="mr-2" height={12} width={12} />
                    {serviceOffered.star_rank} |
                    <div className="text-xs ml-1 mr-2 underline text-sunrise-500">
                      {serviceOffered.review_count}{" "}
                      {Number(serviceOffered.review_count) > 1
                        ? "stars"
                        : "star"}
                    </div>
                  </div>
                )} */}
                {/* {serviceOffered.star_rank && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                    <div className="flex flex-row text-xs">
                      <div className="w-40 min-w-[4rem]">Communication</div>
                      <div className="w-60 self-center mx-8 bg-gray-200 rounded-full">
                        <div
                          className="bg-gray-500 h-1 rounded-full"
                          style={{
                            width: `${
                              (Number(
                                serviceOffered.merchant_review.communication
                              ) /
                                5) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div>{serviceOffered.merchant_review.communication}</div>
                    </div>
                    <div className="flex flex-row text-xs">
                      <div className="w-40 min-w-[4rem]">Value</div>
                      <div className="w-60 self-center mx-8 bg-gray-200 rounded-full">
                        <div
                          className="bg-gray-500 h-1 rounded-full"
                          style={{
                            width: `${
                              (Number(serviceOffered.merchant_review.value) /
                                5) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div>{serviceOffered.merchant_review.value}</div>
                    </div>
                    <div className="flex flex-row text-xs">
                      <div className="w-40 min-w-[4rem]">Accuracy</div>
                      <div className="w-60 self-center mx-8 bg-gray-200 rounded-full">
                        <div
                          className="bg-gray-500 h-1 rounded-full"
                          style={{
                            width: `${
                              (Number(serviceOffered.merchant_review.accuracy) /
                                5) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div>{serviceOffered.merchant_review.accuracy}</div>
                    </div>
                    <div className="flex flex-row text-xs">
                      <div className="w-40 min-w-[4rem]">Outcome</div>
                      <div className="w-60 self-center mx-8 bg-gray-200 rounded-full">
                        <div
                          className="bg-gray-500 h-1 rounded-full"
                          style={{
                            width: `${
                              (Number(serviceOffered.merchant_review.outcome) /
                                5) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div>{serviceOffered.merchant_review.outcome}</div>
                    </div>
                  </div>
                )} */}
                {serviceReviews.length !== 0 && (
                  <>
                    <div className="grid grid-cols-2 gap-12 mt-8">
                      {serviceReviews.slice(0, 4).map((review, idx) => {
                        return (
                          <div id={`review_${idx}`} key={idx}>
                            <div className="flex flex-row align-center self-center">
                              {review.profile_img !== "" ? (
                                <img
                                  draggable="false"
                                  className="rounded-full bg-primary-500 w-10 h-10 bg-no-repeat bg-cover"
                                  src={review.profile_img}
                                  alt={review.firstname}
                                />
                              ) : (
                                <div className="rounded-full defaulticon w-10 h-10 bg-no-repeat bg-cover"></div>
                              )}

                              <div className="flex-col mt-1 pl-3">
                                <div className="text-black">
                                  {review.firstname ?? "-"}
                                </div>
                                <div
                                  className="text-xs"
                                  style={{ lineHeight: 0.5 }}
                                >
                                  {formatDateToMonthYear(review.date_created)}
                                </div>
                              </div>
                            </div>
                            <div className="text-sm mt-5">{review.review}</div>
                          </div>
                        );
                      })}
                    </div>
                    {Number(serviceOffered.review_count) > 4 && (
                      <button
                        onClick={() => {
                          setReviews(true);
                        }}
                        className="cursor-pointer border border-primary-500 px-5 mt-10 text-primary-500 font-semibold py-3 rounded-lg hover:text-primary-100 hover:border-primary-100"
                      >
                        <p className="text-sm">
                          Show all {serviceOffered.review_count} reviews
                        </p>
                      </button>
                    )}
                  </>
                )}
              </div>

              <div className="mt-10" id="merchant-details">
                <div className="flex flex-row text-sm lg:text-base">
                  <div className="w-40 min-w-[5rem]">Language</div>
                  <div>: {serviceOffered.language}</div>
                </div>
                <div className="flex flex-row mt-2 text-sm lg:text-base">
                  <div className="w-40 min-w-[5rem]">Responsive Rate</div>
                  <div>: {serviceOffered.responsive_rate}</div>
                </div>
                <div className="flex flex-row mt-2 text-sm lg:text-base">
                  <div className="w-40 min-w-[5rem]">Response Time</div>
                  <div>: {serviceOffered.responsive_time}</div>
                </div>
              </div>

              <div
                id="anyaya-logo"
                className="flex flex-row my-10 items-center"
              >
                <div className="w-24 h-12 mr-4">
                  <div
                    className="logo h-full w-full "
                    style={{ backgroundPositionY: "center" }}
                  />
                </div>
                <p className="text-xs w-80 italic">
                  To avoid a scam, never communicate outside of the Anyaya
                  Website.
                </p>
              </div>

              {giveStar && (
                <GiveStar
                  id={serviceOffered.service_code}
                  onClose={() => setShowGiveStar(false)}
                  reloadPage={()=> handleInit()}
                />
              )}

              {showReport && (
                <ReportListing
                  id={serviceOffered.service_code}
                  onClose={() => setShowReport(false)}
                  type="listing"
                />
              )}

              {isReviewsOpen && (
                <Review
                  onClose={() => setReviews(false)}
                  reviews={serviceReviews}
                />
              )}
              {isHighlightsOpen && (
                <Highlights
                  onClose={() => setHighlights(false)}
                  highlights={eventHighlights}
                />
              )}
            </div>
          </main>
        </div>
      )}

      {merchantProfile && (
        <ProfileDetails
          merchantCode={serviceOffered?.merchant_code}
          handleOnClose={() => setMerchantProfile(false)}
        />
      )}

      {isMerchantOpen && (
        <MerchantEvents
          isOpen={isMerchantOpen}
          onClose={() => {
            setMerchantModal(false);
          }}
        />
      )}
    </>
  );
};

export default Service;
