import * as React from "react";
const New = () => {
  return (
    <svg className="w-8" width={36} height={36} viewBox="0 0 36 36" fill="none">
      <mask
        id="mask0_1286_1649"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={36}
        height={36}
      >
        <rect width={36} height={36} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1286_1649)">
        <path
          d="M4.5 23.7499V21.2501H15.4999V23.7499H4.5ZM4.5 17.625V15.1249H21.75V17.625H4.5ZM4.5 11.5001V9H21.75V11.5001H4.5ZM24.2501 30V23.7499H18V21.2501H24.2501V15H26.7499V21.2501H33V23.7499H26.7499V30H24.2501Z"
          fill="#E5637F"
        />
      </g>
    </svg>
  );
};

export default New;
